<template>
  <v-container text-xs-center fluid class="pa-0 ma-0">
    <div v-for="section in integrations" :key="section.sectionName">
      <div style="padding-left: 16px;padding-top: 2%;">
        <h3
          class="font-weight-bold"
          style="font-size: 28px; padding-left: 30px;"
        >
          {{ section.sectionName }}
        </h3>
      </div>

      <v-row class="pa-2 ma-2">
        <v-col
          v-for="integration in section.integrations"
          :key="integration.name"
          class="d-flex child-flex"
          cols="12"
          sm="6"
          md="3"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="secondarygrad pa-4 fill-height rounded"
              :elevation="hover ? 6 : 2"
            >
              <v-card-title class="pa-0 my-0">
                <v-btn
                  v-if="integration.video != ''"
                  class="ma-md-2"
                  small
                  icon
                  fab
                  :href="integration.video"
                  target="_blank"
                >
                  <v-icon large color="secondary">play_circle_filled</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-chip
                  v-if="integration.beta"
                  class="ma-2"
                  color="green"
                  text-color="white"
                >
                  BETA
                </v-chip>
              </v-card-title>
              <div class="d-flex justify-center" style="margin-top: 10px;">
                <v-avatar size="62" class="elevation-2">
                  <img
                    :src="integration.image"
                    :alt="integration.name"
                    style=""
                  />
                </v-avatar>
                <span
                  style="padding-top: 20px;padding-left: 10px;font-weight: 700;"
                  >{{ integration.name }}</span
                >
              </div>
              <div class="pt-2">
                <h3 class="text-center text-uppercase secondary--text"></h3>
                <p
                  class="text-center text-md-body-1 secondary--text text-lighten-1"
                ></p>
              </div>
              <div
                class="d-flex justify-center text-sm-body-2 secondary--text"
                style="margin: 30px; text-align: center;"
              >
                {{ integration.description }}
              </div>
              <div
                class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text"
              >
                <p>
                  <v-btn
                    color="primarygrad"
                    block
                    @click="$router.push(integration.path)"
                  >
                    Connect
                  </v-btn>
                </p>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>

    <!-- <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/indiamart.png" alt="External_API" style="height: 45px;width: auto;" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">IndiaMart</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Indiamart account and get all your leads directly
              into godial live.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/indiamart')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/indiamart.png" alt="External_API" style="height: 45px;width: auto;" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">
                IndiaMart V2</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Indiamart account and get all your leads directly
              into godial live.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/indiamartV2')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/justdial.png" alt="External_API" style="width: 44px;height: auto;" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">JustDial</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Justdial account and get all your leads directly into
              godial live.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/justdial')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/quikr1.png" alt="External_API" style="width: 38px;height: auto;" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">
                Quikr</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Quikr account and get all your leads directly into
              godial live.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/quikr')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <div style="padding-left: 16px;padding-top: 2%;">
      <h3 class="font-weight-medium" style="font-size: 28px;">
        Communications
      </h3>
    </div>

    <v-row class="pa-0 ma-0">
      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/interakt1.png" alt="External_API" style="width: 44px;height:auto" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Interakt</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Interakt account and get all your leads directly into
              godial live.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/interakt')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/telecmi.svg" alt="External_API" style="width: 60px;height:auto" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Tele CMI</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Tele CMI and auto dial your leads directly in GoDial.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/telecmi')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <div style="padding-left: 16px;padding-top: 2%;">
      <h3 class="font-weight-medium" style="font-size: 28px;">
        API & Connectors
      </h3>
    </div>

    <v-row class="pa-0 ma-0">
      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/external_api.png" alt="External_API" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">External API</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Push contacts from your own sources, extend the features of GoDial
              or integrate with your existing solution using the GoDial API.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/api_token')">Connect</v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/webhooks.png" alt="External_API" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Webhooks</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect GoDial with Your CRM. GoDial will call the webHook you
              specify to send data for you to consume.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/Web_Hooks')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrately.png" alt="External_API" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Integrately</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect GoDial with Facebook Ads, Google Sheets and 100s of other
              apps at much cheaper costs compared to zapier in a single click.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integrately')">Connect</v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/google_sheet.png" alt="External_API" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Google Sheet Import</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Import A Google Sheet to a GoDial list. This is a one time sync.
              We are working on a realtime sync currently.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/google_sheet')">Connect</v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <div style="padding-left: 16px;padding-top: 2%;">
      <h3 class="font-weight-medium" style="font-size: 28px;">
        CRM
      </h3>
    </div>

    <v-row class="pa-0 ma-0">
      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <img src="../assets/integrations/zoho.png" alt="External_API" style="width: 44px;height:auto" />
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Zoho</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Connect your Zoho CRM and auto dial your leads directly in GoDial.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integration/zoho')">
                  Connect
                </v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <div style="padding-left: 16px;padding-top: 2%;">
      <h3 class="font-weight-medium" style="font-size: 28px;">
        Lead Generation
      </h3>
    </div>

    <v-row class="pa-0 ma-0">
      <v-col class="d-flex child-flex" cols="12" sm="6" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="secondarygrad pa-2 fill-height rounded" :elevation="hover ? 6 : 2">
            <div class="d-flex justify-center">
              <v-avatar size="62" class="elevation-2">
                <v-icon size="36" color="deep-purple">mdi-file-document</v-icon>
              </v-avatar>
              <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Web Forms</span>
            </div>
            <div class="pt-2">
              <h3 class="text-center text-uppercase secondary--text"></h3>
              <p class="text-center text-md-body-1 secondary--text text-lighten-1"></p>
            </div>
            <div class="d-flex justify-center text-sm-body-2 secondary--text">
              Embed a Custom Form in your Website and get Leads from it.
            </div>
            <div class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text">
              <p>
                <v-btn color="primarygrad" block @click="buttonClicked('/integrate_form')">Connect</v-btn>
              </p>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row> -->

    <!-- <v-row class="pa-0 ma-0">
      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-text style="height:350px;">
            <div style="width:100%; text-align:center">
              <img src="../assets/external_api.png" alt="External_API" />
            </div>
            <br />
            <h3 class="font-weight-regular title text-center">
              <v-icon>leak_add</v-icon>
              External API
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Push contacts from your own sources, extend the features of GoDial
              or integrate with your existing solution using the GoDial API.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-row class="connect-btn">
              <v-col class="px-1 mt-3">
                <v-btn
                  color="primarygrad"
                  block
                  @click="buttonClicked('/api_token')"
                  >Connect</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-text style="height:350px;">
            <div style="width:100%; text-align:center">
              <img src="../assets/integrately.png" alt="External_API" />
            </div>
            <br />
            <h3 class="font-weight-regular title text-center">
              <v-icon>mdi-web</v-icon>
              Integrately
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect GoDial with Facebook Ads, Google Sheets and 100s of other
              apps at much cheaper costs compared to zapier in a single click.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-row class="connect-btn">
              <v-col class="px-1 mt-3">
                <v-btn
                  color="primarygrad"
                  block
                  @click="buttonClicked('/integrately')"
                  >Connect</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-btn
              class="ma-md-2"
              small
              icon
              fab
              href="https://youtu.be/mfRlKBhtejo"
              target="_blank"
            >
              <v-icon large color="secondary">play_circle_filled</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/indiamart.png"
              alt="Indiamart"
              contain
              width="200px"
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              IndiaMart
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Indiamart account and get all your leads directly
              into godial live.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/integration/indiamart')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-btn
              class="ma-md-2"
              small
              icon
              fab
              href="https://youtu.be/mfRlKBhtejo"
              target="_blank"
            >
              <v-icon large color="secondary">play_circle_filled</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/indiamart.png"
              alt="Indiamart"
              contain
              width="200px"
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              IndiaMart V2
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Indiamart account and get all your leads directly
              into godial live.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/integration/indiamartV2')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/zoho.png"
              alt="Zoho"
              contain
              width="200px"
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              Zoho
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Zoho CRM and auto dial your leads directly in GoDial.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/integration/zoho')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:330px;">
            <v-img
              src="../assets/webhooks.png"
              alt="External_API"
              contain
              height="160px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center">
              <v-icon>mdi-web</v-icon>
              Webhook
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect GoDial with Your CRM. GoDial will call the webHook you
              specify to send data for you to consume.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/Web_Hooks')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-btn
              class="ma-md-2"
              small
              icon
              fab
              href="https://youtu.be/BopdkXm19Ek"
              target="_blank"
            >
              <v-icon large color="secondary">play_circle_filled</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/facebook1.png"
              alt="facebook"
              contain
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              Facebook Leads
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Receive your facebook leads directly into GoDial, simply connect
              your Lead ad form and we take care of the rest.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primarygrad" block @click="buttonClicked('/fbLeads')">
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-btn
              class="ma-md-2"
              small
              icon
              fab
              href="https://youtu.be/xJrO0ug6TDM"
              target="_blank"
            >
              <v-icon large color="secondary">play_circle_filled</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/justdial1.png"
              alt="Justdial"
              contain
              width="200px"
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              JustDial
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Justdial account and get all your leads directly into
              godial live.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/integration/justdial')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/quikr-guide.png"
              alt="Quikr"
              contain
              width="200px"
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              Quikr
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Quikr account and get all your leads directly into
              godial live.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/integration/quikr')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/interakt1.png"
              alt="Quikr"
              contain
              width="200px"
              height="150px"
              class="my-1 pa-1 pb-9 mx-auto"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              Interakt
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Interakt account and get all your leads directly into
              godial live.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              dark
              block
              @click="buttonClicked('/integration/interakt')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="fill-height rounded-lg">
          <v-col class="text-right" style="margin-bottom: -18%!important;">
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-col>
          <v-card-text style="height:350px;">
            <div class="my-10" style="width:100%; text-align:center;">
              <img
                src="../assets/integrations/cloudTelephony.png"
                alt="Cloud Telephony"
                style="height:100px;"
              />
            </div>
            <br />
            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              Cloud Telephony
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Cloud Telephony
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-row class="connect-btn">
              <v-col class="px-1 mt-3">
                <v-btn
                  color="primarygrad"
                  block
                  @click="buttonClicked('/cloudtelephony')"
                  >Connect</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-title class="pa-0 my-0">
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              BETA
            </v-chip>
          </v-card-title>
          <v-card-text style="height:325px;">
            <v-img
              src="../assets/integrations/telecmi.svg"
              alt="Tele CMI"
              style="width:100%;"
            />

            <h3 class="font-weight-regular title text-center mt-4">
              <v-icon>mdi-web</v-icon>
              Tele CMI
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Connect your Tele CMI and auto dial your leads directly in GoDial.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primarygrad"
              block
              @click="buttonClicked('/integration/telecmi')"
            >
              Connect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-text style="height:350px;">
            <div style="width:100%; text-align:center">
              <img src="../assets/google_sheet.png" alt="External_API" />
            </div>
            <br />
            <h3 class="font-weight-regular title text-center">
              <v-icon>mdi-file-document-multiple</v-icon>
              Google Sheet Import
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Import A Google Sheet to a GoDial list. This is a one time sync.
              We are working on a realtime sync currently.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-row class="connect-btn">
              <v-col class="px-1 mt-3">
                <v-btn
                  color="primarygrad"
                  block
                  @click="buttonClicked('/google_sheet')"
                  >Connect</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="secondarygrad fill-height rounded">
          <v-card-text style="height:350px;">
            <div style="width:100%; text-align:center" class="mt-14">
              <v-icon size="80" color="deep-purple">mdi-file-document</v-icon>
              <h3 class="font-weight-medium">Web Forms</h3>
            </div>
            <br />
            <h3 class="font-weight-regular title text-center mt-9">
              <v-icon>mdi-file-document-multiple</v-icon>
              Web Forms
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Embed a Custom Form in your Website and get Leads from it.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-row class="connect-btn">
              <v-col class="px-1 mt-3">
                <v-btn
                  color="primarygrad"
                  block
                  @click="buttonClicked('/integrate_form')"
                  >Connect</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      integrations: [
        {
          sectionName: "Marketing",
          integrations: [
            {
              name: "Facebook Lead Form Ads",
              description:
                "Connect your Facebook Lead Form Ads and auto dial your leads directly in GoDial.",
              image: require("../assets/integrations/fb.png"),
              path: "/integration/fb",
              beta: true,
              video: "https://youtu.be/BopdkXm19Ek",
            },
            {
              name: "IndiaMart",
              description:
                "Connect your Indiamart account and get all your leads directly into godial live.",
              image: require("../assets/integrations/indm.png"),
              path: "/integration/indiamart",
              beta: true,
              video: "https://youtu.be/mfRlKBhtejo",
            },
            {
              name: "IndiaMart V2",
              description:
                "Connect your Indiamart account and get all your leads directly into godial live.",
              image: require("../assets/integrations/indm.png"),
              path: "/integration/indiamartV2",
              beta: true,
              video: "https://youtu.be/mfRlKBhtejo",
            },
            {
              name: "Justdial",
              description:
                "  Connect your Justdial account and get all your leads directly into godial live.",
              image: require("../assets/integrations/jd.png"),
              path: "/integration/justdial",
              beta: true,
              video: "https://youtu.be/xJrO0ug6TDM",
            },
            {
              name: "TradeIndia",
              description:
                "  Connect your TradeIndia account and get all your leads directly into godial live.",
              image: require("../assets/integrations/ti.png"),
              path: "/integration/tradeindia",
              beta: true,
              video: "",
            },
            {
              name: "Pabbly",
              description:
                "  Connect your Pabbly account and get all your leads directly into godial live.",
              image: require("../assets/integrations/pabbly.png"),
              path: "/integration/pabbly",
              beta: true,
              video: "",
            },
            {
              name: "Quikr",
              description:
                " Connect your Quikr account and get all your leads directly into godial live.",
              image: require("../assets/integrations/qui.png"),
              path: "/integration/quikr",
              beta: true,
              video: "",
            },
          ],
        },
        // {
        //   sectionName: "Communications",
        //   integrations: [
        //     {
        //       name: "Interakt",
        //       description:
        //         "Send events and leads from GoDial to Interakt for Whatsapp",
        //       image: require("../assets/integrations/intr.png"),
        //       path: "/integration/interakt",
        //       beta: false,
        //       video: "",
        //     },
        //     {
        //       name: "Telecmi",
        //       description:
        //         "Connect your Tele CMI and auto dial your leads directly in GoDial.",
        //       image: require("../assets/integrations/telecmi.svg"),
        //       path: "/integration/telecmi",
        //       beta: false,
        //       video: "",
        //     },
        //   ],
        // },
        {
          sectionName: "API & Connectors",
          integrations: [
            {
              name: "External API",
              description:
                "Push contacts from your own sources, extend the features of GoDial or integrate with your existing solution using the GoDial API.",
              image: require("../assets/external_api.png"),
              path: "/api_token",
              beta: false,
              video: "",
            },
            {
              name: "Webhook",
              description:
                "Connect GoDial with Your CRM. GoDial will call the webHook you specify to send data for you to consume.",
              image: require("../assets/webhooks.png"),
              path: "/Web_Hooks",
              beta: false,
              video: "",
            },
            {
              name: "Integrately",
              description:
                "Connect GoDial with Facebook Ads, Google Sheets and 100s of other apps at much cheaper costs compared to zapier in a single click.",
              image: require("../assets/integrately.png"),
              path: "/integrately",
              beta: false,
              video: "",
            },
            {
              name: "Google Sheet Import",
              description:
                " Import A Google Sheet to a GoDial list. This is a one time sync. We are working on a realtime sync currently.",
              image: require("../assets/google_sheet.png"),
              path: "/google_sheet",
              beta: false,
              video: "",
            },
          ],
        },
        {
          sectionName: "CRM",
          integrations: [
            {
              name: "Zoho",
              description:
                "Connect your Zoho CRM and auto dial your leads directly in GoDial.",
              image: require("../assets/integrations/zoho.png"),
              path: "/integration/zoho",
              beta: false,
              video: "",
            },
          ],
        },
        {
          sectionName: "Lead Generation",
          integrations: [
            {
              name: "Web Forms",
              description:
                " Embed a Custom Form in your Website and get Leads from it.",
              image: require("../assets/integrations/webforms.png"),
              path: "/integrate_form",
              beta: false,
              video: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    buttonClicked(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.v-card {
  margin: 5px;
  padding: 1.2rem;
}

.connect-btn {
  /* position: absolute; */
  bottom: 20px;
  width: 90%;
}
</style>
